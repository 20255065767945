import React from "react";
import WebContent from "../ContentScreen/WebContent";
import Services from "../OurServices/Services";
import Footer from "../Footer/Index";
import Mockup from "../Mockupscreen/Mockup";
import "../NewSlide/Asset/style.css";
import Scrollcircle from "../NewSlide/Scrollcircle";
import WaveBanner from "../Banner/WaveBanner";
import "./styles/Styles.css";
import { Parallax } from "react-scroll-parallax";
import Pageslides from "../NewSlide/Pageslides";
import BannerSlide from "../Banner/BannerSlide";
import NewBannerPage from "../Banner/NewBannerPage";
import NewSlidecontent from "../NewSlide/NewSlidecontent";
function Homepage() {
  return (
    <div
      className="scroll-container"
      style={{ overflowX: "hidden", background: "white" }}
    >
      <NewBannerPage />
      <WebContent />
      {/* <Pageslides /> */}
      <NewSlidecontent />
      {/* <Services /> */}
      <Mockup />
      <Footer />
    </div>
  );
}

export default Homepage;
