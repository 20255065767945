import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import React, { useEffect } from 'react'
import cornerlogo from './vectors/connectlogowhite.png'
import mainbackground from './vectors/backgroud image.png'
import connectlogo from './vectors/connectcolorlogo.png'
import linearbuilding from './vectors/linearbuilding1.png'
import './vectors/Mouseaniamtion.css'
const NewBannerPage = () => {
    gsap.registerPlugin(ScrollTrigger);
    useEffect(() => {
        const getRotationValues = () => {
      if (window.matchMedia("(min-width: 1350px)").matches) {
        return {
            Connect:790,
            Souq:-630,
            Welcome: 370,
            ConnectImage:-550,
            welcomeback:-330,
            imageback:-1050,
            connectsmall:-260,
            buildings : -510,
            connectplus :100,
            souqplus :-100  
        }  
      }else if (window.matchMedia("(min-width: 768px) and (max-width: 1349px)").matches) {
        return {
          Connect:660,
          Souq:-550,
          Welcome: 320,
          ConnectImage:-500,
          welcomeback:-280,
          imageback:-850,
          connectsmall:-220,
          buildings : -440,
          connectplus :100,
            souqplus :-110

        };
      } else {
        return {
            Connect:620,
            Souq:-550,
            Welcome: 280,
            ConnectImage:-450,
            welcomeback:-280,
            imageback:-850,
            connectsmall:-220,
            buildings : -440,
            connectplus :50,
            souqplus :-110


        };
      }
    }
        let ctx = gsap.context(() => {
            ScrollTrigger.create({
                trigger: ".firstDiv",
                start: "top top",
                end: "bottom bottom",
                pin: ".firstdivinside",
                scrub: true,
                onUpdate:(self)=>{
                    const progress = self.progress;
                    const { Connect , Souq ,Welcome,ConnectImage,welcomeback,imageback,connectsmall,buildings,connectplus,souqplus } = getRotationValues();
                    if(progress>0 && progress < 0.30){
                        const welcometo = (Welcome * (progress - 0))/0.29
                        gsap.to(".welcome",{translateY:welcometo}) 
                        const connect = (Connect * (progress - 0))/0.29
                        const souq = (Souq * (progress - 0))/0.29
                        gsap.to(".connect",{translateX:connect})
                        gsap.to(".souq",{translateX:souq})
                        const mouseblur = ((progress - 0) / 0.05) * 0.5;
                        const connectimage = (ConnectImage  * (progress - 0))/0.29
                        gsap.to(".connectimage",{translateY:connectimage})
                        const mouseopacity = 1 - ((progress - 0) / 0.05) * 1;
                        const mousemove = (-50 * (progress - 0))/0.05
                        gsap.to(".mouse",{opacity:mouseopacity,translateY:mousemove});
                    }
                    else if(progress >= 0.40 && progress < 0.85){
                      if(progress >= 0.40 && progress < 0.60){
                        const welcometo = Welcome + ( welcomeback * (progress - 0.40))/0.20
                        gsap.to(".welcome",{translateY:welcometo}) 
                        const connectimage = ConnectImage + (imageback  * (progress - 0.40))/0.20
                        gsap.to(".connectimage",{translateY:connectimage})
                        const connect = (connectsmall * (progress - 0.40))/0.20
                        const souq = (connectsmall * (progress - 0.40))/0.20
                        const scale = 1 - ((progress - 0.40) / 0.20) * 0.5; // Example: scale down to 0.5
                        const connectX = Connect + (connectplus * (progress - 0.40))/0.20
                        const souqX = Souq + (souqplus * (progress - 0.40))/0.20
                        gsap.to(".connect",{translateY:connect,scale:scale,translateX:connectX})
                        gsap.to(".souq",{translateY:souq,scale:scale,translateX:souqX})
                        const opacty = 0 + ((progress - 0.40) / 0.10) * 1;
                        gsap.to(".slogan",{opacity:opacty,duration:1})
                      }
                      if(progress >= 0.45 && progress < 0.85){
                        const building  = (buildings * (progress - 0.45))/0.40
                            gsap.to(".linearbuilding",{translateY:building})
                      }
                      if(progress >= 0.65 && progress < 0.85){
                        const opacty = 0 + ((progress - 0.65) / 0.20) * 1;
                        gsap.to(".countries",{opacity:opacty})
                      }

                        // gsap.to(".buton",{opacity:opacty,duration:1})
                    // }else if(progress >= 0.60 && progress < 0.85){
                    //     const building  = (buildings * (progress - 0.60))/0.25
                    //     gsap.to(".linearbuilding",{translateY:building})
                    }
                }
            });
        });
        return () => {
            ctx.revert();
          };
    },[])


  return (
    <>
    <div className='firstDiv' style={{height:'1000vh',width:'100%',overflow:'hidden'}}>
        <div className='firstdivinside' style={{height:'100vh',width:'100%',background:'black'}}>
            <img src='assets/bg-image/plainbackground.png'/>
            <div className="mouse m-5"></div>
            <img src={cornerlogo} width={120} className="position-absolute" style={{top:'3%',left:'2%'}}/>
            <div className='position-absolute connectimage d-flex justify-content-center w-100' style={{top:'100%'}} ><img width={300} src={connectlogo}/></div>
            <div className='position-absolute d-flex justify-content-center w-100 welcome' style={{top:'-18%'}}><p className='mb-0' style={{textTransform:'uppercase',fontSize:35,fontWeight:700,color:'rgb(255 255 255 / 80%)',width:'fit-content',fontFamily:'poppins'}}>Welcome to </p></div>
            <p className='mb-0 connect position-absolute' style={{textTransform:'uppercase',fontSize:70,fontWeight:700,color:'rgb(255 255 255 / 80%)',width:'fit-content',left:'-28%',top:'40%',fontFamily:'poppins'}}>Connect</p>
            <p className='mb-0 souq position-absolute' style={{textTransform:'uppercase',fontSize:70,fontWeight:700,color:'rgb(255 255 255 / 80%)',width:'fit-content',top:'40%',left:'100%',fontFamily:'poppins'}}>Souq</p>
            <button className='buton' onClick={()=>window.location.href="http://connect-client.qcodesinfotech.com/login"} style={{position: 'absolute',left: '90%',top:'3%',border: 'none',color: 'white',fontSize: 20,fontWeight: 600,fontFamily:'poppins'}}>Register</button>
            <div className='position-absolute d-flex justify-content-center w-100' style={{top:'18.5%'}}><p className='mb-0 slogan'style={{color:'white',fontSize:20,opacity:0,fontFamily:'poppins'}}>Connecting people around the <b>globe</b></p></div>
            <div className='position-absolute d-flex justify-content-center w-100' style={{top:'33%'}}><p className='countries textoutline' style={{opacity:0}}>We are officially in <span className='countries text-white textoutline1' style={{opacity:0}}>36+</span> countries </p></div>
            <img className='linearbuilding' src={linearbuilding} style={{width:'100%',position:'absolute',top:'100%',left:0}}/>
            <img />
        </div>

    </div>
    </>
  )
}

export default NewBannerPage