import React from 'react'
import { Fade } from 'react-awesome-reveal'
import './styles/mockup.css'

const Mockup = () => {
  return (
    <div className='backgroundimg' >
      <div className='row align-items-center h-100' >
        <div className='col-7 d-flex justify-content-center'>
          <Fade direction='left' damping={1}>
          <div className='d-flex flex-column gap-3 px-5'>
            {/* <img src="/assets/bg-image/connectsouqmockup.png" width={250}/> */}
            <span>
              <h3 style={{fontSize:'30px',fontWeight:800,color:'black'}}>Why wait?</h3>
            </span>
            <span>
              <h3 style={{fontSize:'30px',fontWeight:800,color:'black'}}>Become a CS member now by downloading our app from</h3>
            </span>
            <div>
            {/* <span className="mt-2">
              <p className='m-0' style={{fontSize:'18px',letterSpacing:0.6}}>Connecting Business across the globe</p>
            </span>
            <span>
              <p className='m-0' style={{fontSize:'18px',letterSpacing:0.1}}>Download the app today!</p>
            </span> */}
            </div>
            <div className='d-flex ' style={{columnGap:20}}> 
              <div className='rounded-2 d-flex p-2' style={{background:'black',columnGap:18,width:158,cursor:'pointer'}}>
                <img src="/assets/icons/apple-xxl.png" width={35} height={35}/>
                <div className='d-flex flex-column '>
                <span style={{color:'white',letterSpacing:0.5,fontSize:14,fontWeight:400}}>Get it on</span>
                <span style={{color:'white',letterSpacing:0.7,fontSize:18,fontWeight:600}}>AppStore</span>
                </div>
              </div>
              <div className='rounded-2 d-flex p-2' style={{background:'black',columnGap:10,cursor:'pointer'}}>
              <img src="/assets/icons/playstore.webp" width={35} height={35}/>
                <div className='d-flex flex-column '>
                <span style={{color:'white',letterSpacing:0.5,fontSize:14,fontWeight:400}}>Download it on</span>
                <span style={{color:'white',letterSpacing:0.7,fontSize:18,fontWeight:600}}>Google Play</span>
                </div>
                </div>
            </div>

            <span>
            <h3 style={{fontSize:'35px',fontWeight:800,color:'black'}}>or</h3>
            </span>
            <div className='d-flex align-items-center' style={{columnGap:20}}>
            <div onClick={()=>window.location.href="http://connect-client.qcodesinfotech.com/login"} className='rounded-2 d-flex px-2 py-3' style={{background:'#8AC53F',columnGap:18,width:'fit-content',cursor:'pointer'}}>
                <span style={{color:'white',letterSpacing:0.5,fontSize:19,fontWeight:600}}>Click here</span>
              </div>
              <span style={{fontSize:'25px',fontWeight:700,color:'black'}}>to become a member</span>
              </div>
          </div>
          </Fade>
          </div>
        <div className='col-5 d-flex justify-content-center'>
            <Fade  direction='up' damping={1}>
              <div className='floating-image'>
    <img src='assets/images/mockupmob.png' width={270}/>
    </div>
    </Fade>
    {/* <Fade direction='down' damping={0} delay={200} style={{position:'relative',left:-50}}>
    <div className='floating-image1'>
    <img src='/assets/images/mobile mockup.png' width={180}/>
    </div>    </Fade> */}
        </div>
        </div>
        
        </div>
  )
}

export default Mockup
