import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import './App.css';
import Homepage from './Component/Homescreen/Homepage';
function App() {

  const route = [
    {path:'/' , element:<Homepage />}
  ]

  return (
    <Router>
      <Routes>
        {route.map((route,index)=>(
          <Route key={index} path={route.path} element={route.element}/>
        ))}      
      </Routes>
    </Router>
  );
}

export default App;
