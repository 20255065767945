import React from 'react'
import "./style.css"
const Index = () => {
    return (
        <div className="footer" >
        <div className="contain row">
          {/*  <div className="col">
           <h1>Company</h1>
            <ul>
              <li>About</li>
              <li>Mission</li>
              <li>Services</li>
              <li>Social</li>
              <li>Get in touch</li>
            </ul>
          </div>
          <div className="col">
            <h1>Products</h1>
            <ul>
              <li>About</li>
              <li>Mission</li>
              <li>Services</li>
              <li>Social</li>
              <li>Get in touch</li>
            </ul>
          </div>
          <div className="col">
            <h1>Accounts</h1>
            <ul>
              <li>About</li>
              <li>Mission</li>
              <li>Services</li>
              <li>Social</li>
              <li>Get in touch</li>
            </ul>
          </div>
          <div className="col">
            <h1>Resources</h1>
            <ul>
              <li>Webmail</li>
              <li>Redeem code</li>
              <li>WHOIS lookup</li>
              <li>Site map</li>
              <li>Web templates</li>
              <li>Email templates</li>
            </ul>
          </div> */}
          <div className='col-5 d-flex justify-content-center align-items-center'>
          <img src="/assets/images/connectlogowhite.png" width={300}/>
          </div>
          <div className="col-4 pt-4">
            <h1>Contact us</h1>
            <ul className='pt-3 pl-0'>
              <li className='py-3'>Building A1, Dubai Digital Park, Dubai Silicon Oasis, Dubai, United Arab Emirates</li>
              <li className='py-3'>CONNECT SOUQ INTERNATIONAL - FZCO</li>
              <li className='py-3'>+97142285285</li>
              <li className='py-3'>info@connectsouq.com</li>
            </ul>
          </div>
          <div className="col-2 social pt-4">
            <h1>Social</h1>
            <ul className='pt-3 pl-0'>
              <li className='m-2'>
                <img
                  src="https://cdn2.iconfinder.com/data/icons/social-media-2285/512/1_Facebook_colored_svg_copy-512.png"
                  width={32}
                  style={{ width: 32 }}
                />
              </li>
              <li className='m-2'>
                <img
                  src="https://cdn2.iconfinder.com/data/icons/social-media-2285/512/1_Linkedin_unofficial_colored_svg-512.png"
                  width={32}
                  style={{ width: 32 }}
                />
              </li>
              <li className='m-2'>
                <img
                  src="https://cdn2.iconfinder.com/data/icons/social-media-2285/512/1_Instagram_colored_svg_1-512.png"
                  width={32}
                  style={{ width: 32 }}
                />
              </li>
            </ul>
            <ul className='pl-0'>
              <li>
                www.qcodesinfotech.com
              </li>
              <li>
                
              </li>
            </ul>
          </div>
          <div className="clearfix" />
        </div>
        <div className='w-100 d-flex justify-content-center'>
            <span>Copyrights@2024 ConnectSouq</span>
        </div>
      </div>
    )
}

export default Index
